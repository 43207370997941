<template>
  <div v-if="!isThirdPartyLogin" class="main">
    <a-tabs
      v-if="!state.wxCodeVisible"
      :activeKey="customActiveKey"
      :tabBarStyle="{ textAlign: 'center', borderBottom: 'unset' }"
      @change="handleTabClick"
    >
      <a-tab-pane v-if="!state.wxLoginVisible" key="tab1" tab="密码登录">
        <a-form
          class="user-layout-login"
          :rules="rules"
          :model="formState"
          @finish="handleFinish"
        >
          <a-form-item name="username" :wrapper-col="{ span: 24 }">
            <a-input
              v-model:value="formState.username"
              size="large"
              placeholder="手机号码"
              :maxlength="11"
            >
              <template #prefix>
                <UserOutlined style="color: rgba(0, 0, 0, 0.25)" />
              </template>
            </a-input>
          </a-form-item>
          <a-form-item name="password" :wrapper-col="{ span: 24 }">
            <a-input-password
              v-model:value="formState.password"
              type="password"
              size="large"
              placeholder="登录密码"
            >
              <template #prefix>
                <LockOutlined style="color: rgba(0, 0, 0, 0.25)" />
              </template>
            </a-input-password>
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 24 }">
            <a-checkbox v-model:checked="checkebox" @change="checkeChange">
              自动登录
            </a-checkbox>
            <router-link
              style="float: right"
              class="forge-password font-size"
              to="/account/findPassword"
              >忘记密码
            </router-link>
          </a-form-item>

          <a-form-item style="margin-top: 24px" :wrapper-col="{ span: 24 }">
            <a-button
              type="primary"
              html-type="submit"
              size="large"
              class="login-button"
              :loading="state.loginBtn"
              :disabled="state.loginBtn"
            >
              登录
            </a-button>
          </a-form-item>

          <a-form-item class="bottom" :wrapper-col="{ span: 24 }">
            <span>还没有账号？</span>
            <router-link class="font-size" to="/account/register">
              立即注册
            </router-link>
          </a-form-item>
        </a-form>
      </a-tab-pane>
      <a-tab-pane v-if="!state.wxLoginVisible" key="tab2" tab="验证码登录">
        <a-form
          class="user-layout-login"
          :rules="VerifyRules"
          :model="userPhone"
          @finish="handleFinish"
        >
          <a-form-item name="value" :wrapper-col="{ span: 24 }">
            <a-input
              size="large"
              v-model:value="userPhone.value"
              type="text"
              placeholder="手机号码"
            >
              <template #prefix>
                <MobileOutlined style="color: rgba(0, 0, 0, 0.25)" />
              </template>
            </a-input>
          </a-form-item>
          <a-row :gutter="16">
            <a-col class="gutter-row" :span="16">
              <a-form-item name="checkCode" :wrapper-col="{ span: 24 }">
                <a-input
                  size="large"
                  type="text"
                  v-model:value="userPhone.checkCode"
                  :maxlength="6"
                  placeholder="验证码"
                >
                  <template #prefix>
                    <MailOutlined style="color: rgba(0, 0, 0, 0.25)" />
                  </template>
                </a-input>
              </a-form-item>
            </a-col>
            <a-col class="gutter-row" :span="8">
              <a-button
                class="getCaptcha"
                v-if="userPhone.buttonVisable"
                :loading="state.verifyBtn"
                @click="getCaptcha"
              >
                获取验证码
              </a-button>
              <a-button class="getCaptcha" :loading="state.verifyBtn" v-if="!userPhone.buttonVisable"
                >{{ userPhone.countDown }} 秒后重发</a-button
              >
            </a-col>
          </a-row>
          <a-form-item :wrapper-col="{ span: 24 }">
            <!-- <a-checkbox> 自动登录 </a-checkbox> -->
            <router-link
              style="float: right"
              class="forge-password font-size"
              to="/account/findPassword"
              >忘记密码
            </router-link>
          </a-form-item>

          <a-form-item
            class="login-button"
            style="margin-top: 24px"
            :wrapper-col="{ span: 24 }"
          >
            <a-button
              type="primary"
              html-type="submit"
              size="large"
              class="login-button"
              :loading="state.loginBtn"
              :disabled="state.loginBtn"
            >
              登录
            </a-button>
          </a-form-item>

          <a-form-item class="bottom" :wrapper-col="{ span: 24 }">
            <span>还没有账号？</span>
            <router-link class="font-size" to="/account/register">
              立即注册
            </router-link>
          </a-form-item>
        </a-form>
      </a-tab-pane>
      <a-tab-pane v-if="!state.wxLoginVisible" key="tab3" tab="小程序登录">
        <div class="tab3 flex-column align-center">
          <div id="qrcode" class="qCode" ref="qrcodeRef"></div>
          <span>请使用微信小程序" 威智造 "扫码登录</span>
        </div>
        <div class="bottom" :wrapper-col="{ span: 24 }">
          <span>还没有账号？</span>
          <router-link class="font-size" to="/account/register">
            立即注册
          </router-link>
        </div>
      </a-tab-pane>
      <a-tab-pane key="tab4" v-if="state.wxLoginVisible" tab="微信绑定手机号">
        <div
          v-if="phoneCheckState.nowLogin"
          style="font-size: 18px; margin-bottom: 20px"
        >
          {{ phoneCheckState.loginText }}
        </div>
        <a-form
          class="user-layout-login"
          v-if="state.wxLoginVisible"
          :model="wxPhoneCheck"
          :rules="rules"
        >
          <a-form-item :wrapper-col="{ span: 24 }">
            当前微信号在平台未绑定对应账号，请验证您的手机号以绑定或注册
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 24 }">
            <a-input
              v-model:value="wxPhoneCheck.phone"
              size="large"
              placeholder="手机号码"
              :maxlength="11"
            >
              <template #prefix>
                <UserOutlined style="color: rgba(0, 0, 0, 0.25)" />
              </template>
            </a-input>
          </a-form-item>
          <a-row :gutter="16">
            <a-col class="gutter-row" :span="16">
              <a-form-item name="checkCode" :wrapper-col="{ span: 24 }">
                <a-input
                  size="large"
                  type="text"
                  v-model:value="wxPhoneCheck.checkCode"
                  :maxlength="6"
                  placeholder="验证码"
                >
                  <template #prefix>
                    <MailOutlined style="color: rgba(0, 0, 0, 0.25)" />
                  </template>
                </a-input>
              </a-form-item>
            </a-col>
            <a-col class="gutter-row" :span="8">
              <a-button
                class="getCaptcha"
                v-if="userPhone.buttonVisable"
                :loading="state.loginBtn"
                @click="wxGetCaptcha"
              >
                获取验证码
              </a-button>
              <a-button class="getCaptcha" v-if="!userPhone.buttonVisable"
                >{{ userPhone.countDown }} 秒后重发</a-button
              >
            </a-col>
          </a-row>
          <a-form-item>
            <div v-if="phoneCheckState.bindOrRegist">
              <div
                v-if="phoneCheckState.result === false"
                style="font-size: 16px; margin-bottom: 20px"
              >
                <img src="@/assets/jingao_icon.png" alt="" class="logo" />
                该手机号码未注册平台账号，可立即注册
              </div>
              <a-avatar
                v-if="phoneCheckState.result"
                size="large"
                :src="phoneCheckState.userAvatar"
                class="antd-pro-global-header-index-avatar"
              />
              <span v-if="phoneCheckState.result" style="margin-left: 10px"
                >{{ phoneCheckState.userName }}（{{
                  wxPhoneCheck.phone
                }}）</span
              >
            </div>
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 24 }">
            <div>
              <a-button
                v-if="phoneCheckState.phoneCheck"
                key="submit"
                type="primary"
                size="large"
                class="login-button"
                :loading="state.loginBtn"
                @click="phoneCheck()"
                >确认</a-button
              >
              <a-button
                v-if="phoneCheckState.bindOrRegist && phoneCheckState.result"
                key="submit"
                type="primary"
                size="large"
                class="login-button"
                :loading="state.loginBtn"
                @click="wxBindOrRegist()"
                >绑定</a-button
              >
              <a-button
                v-if="
                  phoneCheckState.bindOrRegist &&
                  phoneCheckState.result === false
                "
                key="submit"
                type="primary"
                size="large"
                class="login-button"
                :loading="state.loginBtn"
                @click="wxBindOrRegist()"
                >注册</a-button
              >
            </div>
          </a-form-item>
          <a-form-item style="text-align: right" :wrapper-col="{ span: 24 }">
            <a class="font-size" @click="backNormalLogin"> 返回普通登录 </a>
          </a-form-item>
        </a-form>
      </a-tab-pane>
    </a-tabs>
    <a-spin v-if="state.wxCodeVisible" :spinning="state.loginBtn">
      <div class="tab4 flex-column align-center">
        <wxlogin
          :appid="wxLoginData.appid"
          :scope="wxLoginData.scope"
          :theme="wxLoginData.theme"
          :redirect_uri="wxLoginData.redirect_uri"
        ></wxlogin>
        <a-button
          style="margin-bottom: 16px; margin-top: 0px"
          @click="hideWxLoginCode"
          >返回普通登录</a-button
        >
      </div>
    </a-spin>
    <div
      v-if="!state.wxCodeVisible && !state.wxLoginVisible"
      style="text-align: center"
    >
      <a-divider style="margin-bottom: 16px; margin-top: 0px">
        <a style="color: black" @click="showWxLoginCode">
          <img src="@/assets/wxLogo.svg" alt="" class="logo" />
          微信登录
        </a>
      </a-divider>
    </div>
  </div>
  <div v-else>

  </div>
</template>

<script>
import { ref, reactive, getCurrentInstance, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { getProfileDetail } from '@/api/IdentityAPI'
import { UserOutlined, LockOutlined, MobileOutlined, MailOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { Pclogin, wxUserInfo, login, thirdPartyLogin, verifyLogin, sendVerifycationCode, PhoneCheck } from '@/api/account/AccountAPI'
import { isEmpty } from '@/assets/common.js'
import { Decrypt, Encrypt } from '@/utils/secret.js'
import QRCode from 'qrcodejs2'
import { getEnvVars } from '@/api/config'
import wxlogin from 'vue-wxlogin'
import * as signalR from '@microsoft/signalr'
import api from '@/api/API'
import { getUrlParam } from '@/utils/queryParam.js'

export default {
  name: 'account-login',
  components: {
    UserOutlined,
    LockOutlined,
    MobileOutlined,
    MailOutlined,
    wxlogin,
  },
  mounted () {
  },
  setup () {
    const qrcodeRef = ref()
    const store = useStore()
    const router = useRouter()
    const { ctx, proxy } = getCurrentInstance()
    const wxQuery = {
      code: '',
      state: '',
      unionId: '',
      pageAppOpenId: '',
      name: '',
      avatarUrl: '',
    }
    const phoneCheckState = reactive({
      userName: '',
      userAvatar: '',
      phoneCheck: false,
      bindOrRegist: false,
      result: false,
      buttonContent: '',
      nowLogin: false,
      loginText: '已获取到微信号，正在登录...',
    })
    const wxPhoneCheck = reactive({
      phone: '',
      checkCode: '',
    })
    const customActiveKey = ref('tab1')
    const checkebox = ref(false)
    // 模型定义
    const formState = reactive({
      username: '',
      password: '',
      value: '',
      checkCode: '',
      arrys: [],
      arry: [],
    })
    const wxLoginData = reactive({
      appid: 'wx598b98538e109123',
      scope: 'snsapi_login',
      theme: 'black',
      redirect_uri: 'https%3A%2F%2Fwplatform.yaweicloud.com%2Faccount%2Flogin',
    })
    const userPhone = reactive({
      value: '',
      checkCode: '',
      buttonVisable: true,
      countDown: null,
      timer: null,
      password: '',
      countDownTimer: '',
    })
    const state = reactive({
      time: 60,
      loginBtn: false,
      verifyBtn: false,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      smsSendBtn: false,
      wxLoginVisible: false,
      wxCodeVisible: false,
    })
    const showWxLoginCode = () => {
      state.wxCodeVisible = true
    }
    const hideWxLoginCode = () => {
      state.wxCodeVisible = false
    }
    const backNormalLogin = () => {
      state.wxLoginVisible = false
      phoneCheckState.phoneCheck = false
      customActiveKey.value = 'tab1'
    }
    const handleFinish = () => {
      state.loginBtn = true
      switch (customActiveKey.value) {
        case 'tab1':
          login(formState.username, formState.password)
            .then(response => {
              // 执行login的异常不会被catch捕获，而是包含在response返回的数据中
              if (response.data == null) {
                throw new Error(response)
              }
              if (response.status && response.status === 200) {
                store.dispatch('account/setToken', response.data.rawData)
                localStorage.setItem('isBan', JSON.stringify(response.data.isBan))
                store.dispatch('account/setTenant', {
                  tenantId: response.data.tenantId,
                })
                console.log()
                getProfileDetail().then(data => {
                  store.dispatch('profile/setProfile', data)
                  if (response.data.tenantId === null) {
                    router.push({ path: '/account/create' })
                  } else {
                    if (response.data.isBan) {
                      message.error('您当前所在企业已被禁用,请切换其他企业')
                      router.push({ path: '/account/jion' })
                      return
                    }
                    if (data.status === 1) {
                      state.loginBtn = false
                      window.localStorage.clear()
                      message.error('当前账户已被禁用，如有疑问请联系运维人员')
                      return
                    }
                    router.push({
                      path: '/WorkManagement/WorkBench',
                    })
                  }
                })
                  .catch(err => {
                    state.loginBtn = false
                    console.log(err)
                  })
              }
            })
            .catch(err => {
              state.loginBtn = false
              // message.error(err.response.data.error.message)
              console.log(err)
            })
          // .finally(() => { state.loginBtn = false })
          break
        case 'tab2':
          verifyLogin(userPhone.value, userPhone.checkCode)
            .then(response => {
              if (response.data == null) {
                throw new Error(response)
              }
              if (response.status && response.status === 200) {
                store.dispatch('account/setToken', response.data.rawData)
                localStorage.setItem('isBan', JSON.stringify(response.data.isBan))
                store.dispatch('account/setTenant', {
                  tenantId: response.data.tenantId,
                })
                getProfileDetail().then(data => {
                  store.dispatch('profile/setProfile', data)
                  if (response.data.tenantId === null) {
                    router.push({ path: '/account/create' })
                  } else {
                    if (response.data.isBan) {
                      message.error('您当前所在企业已被禁用,请切换其他企业')
                      router.push({ path: '/account/jion' })
                      return
                    }
                    if (data.status === 1) {
                      window.localStorage.clear()
                      state.loginBtn = false
                      message.error('当前账户已被禁用，如有疑问请联系运维人员')
                      return
                    }
                    router.push({ path: '/WorkManagement/WorkBench' })
                  }
                })
                  .catch(err => {
                    state.loginBtn = false
                    console.log(err)
                  })
              }
            })
            .catch(err => {
              state.loginBtn = false
              // message.error(err.response.data.error.message)
              console.log(err)
            })
          // .finally(() => { state.loginBtn = false })
          break
      }
    }
    const handleTabClick = (key) => {
      customActiveKey.value = key
      if (key === 'tab3') {
        nextTick(() => {
          qrcodeCreate()
        })
        qrLogin()
      }
    }
    const countDown = () => {
      const TIME_COUNT = 60
      userPhone.countDown = TIME_COUNT
      userPhone.buttonVisable = false
      userPhone.countDownTimer = setInterval(() => {
        if (userPhone.countDown > 0 && userPhone.countDown <= TIME_COUNT) {
          userPhone.countDown--
        } else {
          userPhone.buttonVisable = true
          clearInterval(userPhone.countDownTimer)
          userPhone.countDownTimer = null
        }
      }, 1000)
    }
    const phoneCheck = () => {
      state.loginBtn = true
      // 验证手机号的所属账号 返回用户名以及头像
      console.log(wxPhoneCheck, 'wxPhoneCheck')
      PhoneCheck(wxPhoneCheck).then(response => {
        if (response.data.result === true) {
          phoneCheckState.userName = response.data.userName
          phoneCheckState.userAvatar = response.data.avatarUrl
          phoneCheckState.result = true
        } else {
          phoneCheckState.result = false
        }
        phoneCheckState.phoneCheck = false
        phoneCheckState.bindOrRegist = true
        state.loginBtn = false
      }).catch(err => {
        state.loginBtn = false
        // message.error(err.response.data.error.message)
        console.log(err)
      })
    }
    const getCaptcha = (e) => {

      if (!userPhone.value || userPhone.value.trim() === '') {
        message.error('请先填写手机号')
        return
      }
      state.verifyBtn = true
      countDown()
      sendVerifycationCode(userPhone.value)
        .then(response => {
          state.verifyBtn = false
        })
    }
    const wxGetCaptcha = (e) => {

      if (!wxPhoneCheck.phone) {
        message.error('请先填写手机号')
        return
      }
      state.verifyBtn = true
      countDown()
      sendVerifycationCode(wxPhoneCheck.phone)
        .then(response => {
          state.verifyBtn = false
        })
    }
    const wxBindOrRegist = () => {
      state.loginBtn = true
      const wxRegistObj = {
        phoneNumber: wxPhoneCheck.phone,
        email: '',
        userName: wxPhoneCheck.phone,
        password: '123456',
        checkCode: wxPhoneCheck.checkCode,
        pageAppOpenId: wxQuery.pageAppOpenId,
        unionId: wxQuery.unionId,
        wxUserName: wxQuery.name,
        wxUserAvatar: wxQuery.avatarUrl,
      }
      api
        .post('/api/app/account/bind', wxRegistObj)
        .then(({ data }) => {
          Pclogin(wxQuery.pageAppOpenId, wxQuery.unionId)
            .then(response => {
              // 执行login的异常不会被catch捕获，而是包含在response返回的数据中
              if (response.data == null) {
                throw new Error(response)
              }
              if (response.status && response.status === 200) {
                // console.log(typeof response.data.rawData)
                store.dispatch('account/setToken', response.data.rawData)
                store.dispatch('account/setTenant', {
                  tenantId: response.data.tenantId,
                })
                localStorage.setItem('isBan', JSON.stringify(response.data.isBan))
                getProfileDetail().then(data => {
                  store.dispatch('profile/setProfile', data)
                  if (response.data.tenantId === null) {
                    router.push({ path: '/account/create' })
                  } else {
                    if (response.data.isBan) {
                      message.error('您当前所在企业已被禁用,请切换其他企业')
                      router.push({ path: '/account/jion' })
                      return
                    }
                    if (data.status === 1) {
                      message.error('当前账户已被禁用，如有疑问请联系运维人员')
                      window.localStorage.clear()
                      return
                    }
                    router.push({
                      path: '/WorkManagement/WorkBench',
                    })
                  }
                })
                  .catch(err => {
                    console.log(err)
                  })
              }
            }).catch(err => {
              console.log(err)
            })
        }).catch(err => {
          state.wxLoginVisible = false
          customActiveKey.value = 'tab1'
          state.loginBtn = false
          console.log(err)
          // this.$message.error('注册失败' + err)
        })
    }
    const wxBacktoCheck = () => {
      phoneCheckState.phoneCheck = true
      phoneCheckState.bindOrRegist = false
    }
    const clear = () => {
      state.wxLoginVisible = false
    }
    const token = () => { // token有效，直接跳转主页
      const data = JSON.parse(localStorage.getItem('token'))
      const isBan = JSON.parse(localStorage.getItem('isBan'))
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      // const tenantData = localStorage.getItem('tenant')
      if (isEmpty(data)) {
        return
      }
      // if (tenantData === 'null') {
      //   router.push({ path: '/account/create' })
      //   return
      // }
      const timer = setInterval(() => {
        const now = new Date().valueOf() // 修改数据date
        if (now < data.expire_time) {
          if (userInfo.status === 1) {
            message.error('当前账户已被禁用，如有疑问请联系运维人员')
            window.localStorage.clear()
            return
          }
          if (isBan) {
            message.error('您当前所在企业已被禁用,请切换其他企业')
            router.push({ path: '/account/jion' })
            return
          }
          router.push({ path: '/WorkManagement/WorkBench' })
        }
      }, 1000)
      clearInterval(timer)
    }
    const rules = {
      username: [
        {
          required: true,
          message: '请输入手机号码',
          trigger: 'blur',
        },
        {
          len: 11,
          message: '请输入正确的11位手机号码',
          trigger: 'blur',
        },
      ],

      password: [
        {
          required: true,
          message: '请输入密码',
          trigger: 'blur',
        },
        {
          min: 1,
          max: 30,
          message: '请输入密码',
          trigger: 'blur',
        },
      ],
    }// 验证规则
    const VerifyRules = {
      value: [
        {
          required: true,
          message: '请输入手机号码',
          trigger: 'blur',
        },
        {
          len: 11,
          message: '请输入正确的11位手机号码',
          trigger: 'blur',
        },
      ],
      checkCode: [
        {
          required: true,
          message: '请输入验证码',
          trigger: 'blur',
        },
        {
          len: 6,
          message: '请输入正确的6位数字验证码',
          trigger: 'blur',
        },
      ],

    }
    // 生成二维码
    const qrcodeCreate = () => {
      const qrcode = new QRCode(qrcodeRef.value, {
        width: 250, // 设置宽度
        height: 250, // 设置高度
        colorDark: '#000000',
        colorLight: '#ffffff',
        text: signalRConnecting.loginData,
      })
      qrcode._el.title = '' // 鼠标悬浮不展示title
    }
    // 微信登录
    const qrLogin = () => {
      // 接到通知信息
      signalRConnecting.connection.on('scanQRCodeLoginAsync', function (message) {
        store.dispatch('account/setToken', message.loginOutput.rawData)
        store.dispatch('account/setTenant', {
          tenantId: message.loginOutput.tenantId,
        })
        localStorage.setItem('isBan', JSON.stringify(message.loginOutput.isBan))
        getProfileDetail().then(data => {
          store.dispatch('profile/setProfile', data)
          if (message.loginOutput.tenantId === null) {
            router.push({ path: '/account/create' })
          } else {
            if (message.loginOutput.isBan) {
              message.error('您当前所在企业已被禁用,请切换其他企业')
              router.push({ path: '/account/jion' })
              return
            }
            if (data.status === 1) {
              window.localStorage.clear()
              message.error('当前账户已被禁用，如有疑问请联系运维人员')
              return
            }
            router.push({ path: '/WorkManagement/WorkBench' })
          }
        })
      })
    }
    // signalr连接
    const signalRConnecting = { connection: null, loginData: null }
    const signalRConnection = () => {
      const { apiUrl } = getEnvVars()
      const hubUrl = apiUrl + '/api/chat'
      const connection = new signalR.HubConnectionBuilder()
        .withAutomaticReconnect()
        .withUrl(hubUrl)
        .build()
      signalRConnecting.connection = connection
      connection
        .start()
        .then(function () {
          // 获取当前客户端的连接id
          connection.invoke('LoginConnection').then(res => {
            signalRConnecting.loginData = JSON.stringify(res)
          })
        })
        .catch(err => console.log(err))
    }
    const checkeChange = (e) => {
      if (e.target.checked === true) {
        setCookie(formState.username, formState.password, 7)
      }
    }
    // 设置cookie
    const setCookie = (name, pwd, exdays) => {
      var exdate = new Date()
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays)
      // console.log(exdate)
      window.document.cookie = 'userName' + '=' + Encrypt(name) + ';path=/;expires=' + exdate.toGMTString()
      window.document.cookie = 'userPwd' + '=' + Encrypt(pwd) + ';path=/;expires=' + exdate.toGMTString()
    }
    // 获取cookie
    const getCookie = () => {
      if (document.cookie.toString().length > 0) {
        var cookies = document.cookie.split(';')
        var userName = ''
        var userPwd = ''
        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i].trim()
          if (cookie.indexOf('userName=') === 0) {
            userName = cookie.split('=')[1]
          }
          if (cookie.indexOf('userPwd=') === 0) {
            userPwd = cookie.split('=')[1]
          }
        }
        if (userName && userPwd) {
          formState.username = Decrypt(userName)
          formState.password = Decrypt(userPwd)
          handleFinish()
        }
      }
    }
    const urlQueryParams = reactive({
      sign: getUrlParam('sign'),
      userId: getUrlParam('userId') ?? '',
      telephone: getUrlParam('telephone'),
      timestamp: getUrlParam('timestamp'),
      nonce: getUrlParam('nonce'),
      userName: getUrlParam('userName') ?? '',
      firmName: getUrlParam('firmName') ?? '',
      industry: getUrlParam('industry') ?? '',
      addressProvince: getUrlParam('addressProvince') ?? '',
      addressCity: getUrlParam('addressCity') ?? '',
      addressRegion: getUrlParam('addressRegion') ?? '',
      address: getUrlParam('address') ?? '',
    })
    const isThirdPartyLogin = urlQueryParams.sign && urlQueryParams.telephone && urlQueryParams.timestamp && urlQueryParams.nonce
    return { isThirdPartyLogin, urlQueryParams, wxLoginData, VerifyRules, rules, checkebox, customActiveKey, formState, state, userPhone, wxQuery, wxPhoneCheck, phoneCheckState, backNormalLogin, showWxLoginCode, hideWxLoginCode, wxBindOrRegist, wxBacktoCheck, countDown, handleFinish, handleTabClick, getCaptcha, wxGetCaptcha, clear, token, signalRConnection, signalRConnecting, qrcodeRef, qrLogin, checkeChange, setCookie, getCookie, phoneCheck }
  },
  created () {
    const store = useStore()
    const router = useRouter()
    this.token()
    this.signalRConnection()
    this.getCookie()
    if (this.$route.query.code !== undefined) {
      this.phoneCheckState.nowLogin = true
      this.state.loginBtn = true
      this.state.wxCodeVisible = true
      api
        .get('/api/app/pub-method/get-wx-login-info', {
          params: {
            code: this.$route.query.code,
          },
        })
        .then(response => {
          this.wxQuery.code = this.$route.query.code
          this.wxQuery.state = this.$route.query.state
          this.wxQuery.pageAppOpenId = response.data.openid
          this.wxQuery.unionId = response.data.unionid
          this.wxQuery.name = response.data.nickname
          this.wxQuery.avatarUrl = response.data.headimgurl
          Pclogin(this.wxQuery.pageAppOpenId, this.wxQuery.unionId)
            .then(response => {
              // 执行login的异常不会被catch捕获，而是包含在response返回的数据中
              if (response.data == null) {
                throw new Error(response)
              }
              if (response.status && response.status === 200) {
                // console.log(typeof response.data.rawData)
                store.dispatch('account/setToken', response.data.rawData)
                store.dispatch('account/setTenant', {
                  tenantId: response.data.tenantId,
                })
                localStorage.setItem('isBan', JSON.stringify(response.data.isBan))
                getProfileDetail().then(data => {
                  store.dispatch('profile/setProfile', data)
                  if (response.data.tenantId === null) {
                    router.push({ path: '/account/create' })
                  } else {
                    if (response.data.isBan) {
                      message.error('您当前所在企业已被禁用,请切换其他企业')
                      router.push({ path: '/account/jion' })
                      return
                    }
                    if (data.status === 1) {
                      window.localStorage.clear()
                      message.error('当前账户已被禁用，如有疑问请联系运维人员')
                      return
                    }
                    router.push({
                      path: '/WorkManagement/WorkBench',
                    })
                  }
                })
                  .catch(err => {
                    console.log(err)
                  })
              }
            }).catch(err => {
              console.log(err)
              this.state.loginBtn = false
              if (err.response.data.error.message === '该账号不存在') {
                this.customActiveKey = 'tab4'
                this.state.wxLoginVisible = true
                this.phoneCheckState.nowLogin = false
                this.phoneCheckState.phoneCheck = true
              }
            })
        })
        .catch(err => {
          console.log(err)
          this.$notification.error({
            message: '登录失败',
            description:
              '微信登录失败，请尝试重新扫码登录',
          })
          this.state.loginBtn = false
        })
    }
    this.thirdPartyLogin(store, router)
  },
  methods: {
    // 智维云三方登录
    thirdPartyLogin (store, router) {
      if (!this.isThirdPartyLogin) {
        return
      }

      const decodedUserName = decodeURIComponent(this.urlQueryParams.userName)
      const decodedFirmName = decodeURIComponent(this.urlQueryParams.firmName)
      const decodedIndustry = decodeURIComponent(this.urlQueryParams.industry)
      const decodedAddressProvince = decodeURIComponent(this.urlQueryParams.addressProvince)
      const decodedAddressCity = decodeURIComponent(this.urlQueryParams.addressCity)
      const decodedAddressRegion = decodeURIComponent(this.urlQueryParams.addressRegion)
      const decodedAddress = decodeURIComponent(this.urlQueryParams.address)

      const thirdPartyLoginParams = {
        sign: this.urlQueryParams.sign,
        userId: this.urlQueryParams.userId,
        telephone: this.urlQueryParams.telephone,
        timestamp: this.urlQueryParams.timestamp,
        nonce: this.urlQueryParams.nonce,
        userName: decodedUserName,
        firmName: decodedFirmName,
        industry: decodedIndustry,
        addressProvince: decodedAddressProvince,
        addressCity: decodedAddressCity,
        addressRegion: decodedAddressRegion,
        address: decodedAddress,
      }

      thirdPartyLogin(thirdPartyLoginParams)
        .then(response => {
          if (response.data == null) {
            throw new Error(response)
          }
          if (response.status && response.status === 200) {
            store.dispatch('account/setToken', response.data.rawData)
            localStorage.setItem('isBan', JSON.stringify(response.data.isBan))
            store.dispatch('account/setTenant', {
              tenantId: response.data.tenantId,
            })
            getProfileDetail().then(data => {
              store.dispatch('profile/setProfile', data)
              if (response.data.tenantId === null) {
                router.push({ name: 'account-create', params: thirdPartyLoginParams })
              } else {
                if (response.data.isBan) {
                  message.error('您当前所在企业已被禁用,请切换其他企业')
                  router.push({ path: '/account/jion' })
                  return
                }
                if (data.status === 1) {
                  window.localStorage.clear()
                  message.error('当前账户已被禁用，如有疑问请联系运维人员')
                  return
                }
                router.push({
                  path: '/WorkManagement/WorkBench',
                })
              }
            })
              .catch(err => {
                console.log(err)
              })
          }
        })
        .catch(err => {
          console.log(err)
          // window.location.href = window.location.pathname
          // router.replace({ path: '/account/login' })
        })
    },
  },
  // async beforeRouteUpdate (to, from) {
  //   if (to.name !== 'account-login' || from.name !== 'account-login') {
  //     return
  //   }
  //   const {
  //     sign,
  //     userId,
  //     telephone,
  //     timestamp,
  //     nonce,
  //     userName,
  //     firmName,
  //     industry,
  //     addressProvince,
  //     addressCity,
  //     addressRegion,
  //     address,
  //   } = to.query

  //   this.urlQueryParams = {
  //     sign,
  //     userId: userId ?? '',
  //     telephone,
  //     timestamp,
  //     nonce,
  //     userName: userName ?? '',
  //     firmName: firmName ?? '',
  //     industry: industry ?? '',
  //     addressProvince: addressProvince ?? '',
  //     addressCity: addressCity ?? '',
  //     addressRegion: addressRegion ?? '',
  //     address: address ?? '',
  //   }

  //   this.isThirdPartyLogin = sign && telephone && timestamp && nonce
  // },
}
</script>

<style lang="scss" scoped>
@import "@/assets/common.scss";
.main {
  padding: 40px;

  .ant-form-item-control-wrapper {
    width: 100% !important;
  }
  .user-layout-login {
    label {
      font-size: 14px;
    }

    .getCaptcha {
      display: block;
      width: 100%;
      height: 40px;
    }

    .forge-password {
      font-size: 14px;
    }

    button.login-button {
      padding: 0 15px;
      font-size: 16px;
      height: 40px;
      width: 100%;
      background-color: #134ccf;
      color: #fff;
    }

    .user-login-other {
      text-align: left;
      margin-top: 24px;
      line-height: 22px;

      .item-icon {
        font-size: 24px;
        color: rgba(0, 0, 0, 0.2);
        margin-left: 16px;
        vertical-align: middle;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
          color: #1890ff;
        }
      }
      .register {
        float: right;
      }
    }
  }
  .bottom {
    margin-top: 15px;
    text-align: center;
    font-size: 12px;
  }
  .tab3 {
    width: 100%;
    .qCode {
      height: 250px;
      width: 250px;
      overflow: hidden;
    }
  }
  .logo {
    width: 40px;
    height: 40px;
    // background-color: #134CCF;
  }
}
::v-deep(.ant-tabs) {
  height: 403px;
}
::v-deep(.ant-tabs-bar) {
  margin: 0 0 35px 0;
}
::v-deep(.ant-tabs-tab:hover) {
  color: #134ccf;
}
::v-deep(.ant-tabs-nav .ant-tabs-tab-active) {
  color: #134ccf;
}
::v-deep(.ant-tabs-nav .ant-tabs-tab:active) {
  color: #134ccf;
}
::v-deep(.ant-tabs-ink-bar) {
  background-color: #134ccf;
}
::v-deep(.ant-btn:hover, .ant-btn:focus) {
  border-color: #134ccf;
  color: #134ccf;
}
::v-deep(.login-button.ant-btn:hover, .ant-btn:focus) {
  border-color: #134ccf;
  color: #fff;
  opacity: 0.7;
}
::v-deep(.ant-input-affix-wrapper:hover, .ant-input-affix-wrapper:focus) {
  border-color: #134ccf;
}
::v-deep(.ant-input-affix-wrapper-focused) {
  border-color: #134ccf;
  box-shadow: none;
}
::v-deep(.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover
    .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner) {
  border-color: #134ccf;
}
::v-deep(.ant-checkbox-checked .ant-checkbox-inner) {
  border-color: #134ccf;
  background-color: #134ccf;
}
</style>
